
function GeneralConditionsOfUse() {
  return (
    <div className="container mb-5">
      <div className="row justify-content-center">
        
        <h1>General Terms of Use</h1>
    <p>
        These General Terms of Use govern the relationship between EIfER Europäisches Institut für Energieforschung EDF-KIT EWIV (hereinafter referred to as "EIfER") and users of this website. By accessing and using this website, users accept these terms in full.
    </p>

    <h2>1. Scope of Use</h2>
    <p>
        This website is made available to private individuals for informational and interactive purposes. Users can access publicly available content and, if they choose to create an account, submit suggestions to EIfER. The creation of an account and the submission of suggestions do not create any contractual obligation between the user and EIfER.
    </p>

    <h2>2. User Accounts and Submission of Suggestions</h2>
    <p>
        Users may create an account to submit suggestions to EIfER. The purpose of the account is to facilitate communication between EIfER and users regarding their submissions. Submission of a suggestion does not guarantee its publication. EIfER reserves the sole discretion to determine whether a suggestion is published, modified, or discarded. Users acknowledge that their submitted content may be edited before publication. Users must ensure that their suggestions do not infringe third-party rights or violate applicable laws.
    </p>

    <h2>3. No Rights to Publication</h2>
    <p>
        Users have no entitlement to the publication of their submitted suggestions. EIfER reserves the right to review, modify, or refuse submissions at its sole discretion. Users cannot claim compensation or damages in the event that their content is not published or is removed at a later date.
    </p>

    <h2>4. Intellectual Property</h2>
    <p>
        By submitting suggestions, users grant EIfER a non-exclusive, worldwide, royalty-free license to use, modify, and publish the submitted content. Users affirm that they own all necessary rights to the submitted content and indemnify EIfER from any claims by third parties regarding potential infringements. The license granted by the user remains in force unless explicitly revoked by the user in writing.
    </p>

    <h2>5. Limitation of Liability</h2>
    <p>
        EIfER strives to provide accurate and up-to-date content. However, it does not guarantee that the information provided is free from errors, omissions, or inaccuracies. Users access the website at their own risk. EIfER assumes no liability for damages arising from the use or inability to use the website or its content. This includes, but is not limited to, direct, indirect, incidental, consequential, or punitive damages.
    </p>

    <h2>6. Data Protection</h2>
    <p>
        Personal data is collected and processed in compliance with the General Data Protection Regulation (GDPR). Users can find detailed information on data processing and their rights in the Privacy Policy. By creating an account or submitting suggestions, users consent to the processing of their personal data in accordance with EIfER’s data protection policies.
    </p>

    <h2>7. Modifications to Terms of Use</h2>
    <p>
        EIfER reserves the right to amend these Terms of Use to reflect changes in legal, regulatory, or technical requirements. Any modifications will be communicated to users in an appropriate manner.
    </p>
    <p>
        If the changes significantly affect the rights or obligations of users, they will be notified in advance via email or website announcement at least 30 days before the changes take effect.
    </p>
    <p>
        If users continue to use the website after the notification period, this shall constitute acceptance of the updated Terms of Use, provided the changes do not impose new obligations on the user. If a user does not agree to the changes, they may terminate their account before the modifications take effect.
    </p>
    <p>
        Changes required by law, regulatory directives, or security reasons may take effect immediately upon publication. In such cases, users will still be notified as soon as possible.
    </p>

    <h2>8. Governing Law and Jurisdiction</h2>
    <p>
        These Terms of Use are governed by the laws of the Federal Republic of Germany. Any disputes arising from the use of this website shall be subject to the exclusive jurisdiction of the courts in Karlsruhe, Germany.
    </p>

      {/* <h1>1. Definitions</h1>
      <p>In the following H2SHIPS General Conditions of Use (GCU), each of the expressions mentioned below will have the meaning given in its definition, i.e. :</p>
      <p>“Site”: refers to the H2SHIPS site made available by EIFER on the Internet at <a href="https://h2ships.org/">https://h2ships.org/</a>.</p>
      <p>“Account": refers to the space created by the User to access the Site.</p>
      <p>“User": any person using the Services of the Site. Users can create an account to be able to publish Contributions.</p>
      <p>“Contribution(s)”: refers to all information/contributions submitted for publication to the administrators by the Users, 
        via the ‘Add Content’ tab. Contributions may only relate to the use of hydrogen in naval transport.</p>
      <p>“Content": refers to all information, data, resources, news, Contributions and other content published on the site under the ‘News&Events’, ‘Projects Database’ and ‘Resources’ tabs.</p>
      <p>“Registration data": all the information that the User must provide during the Account creation procedure (login, password, e-mail address and, optionally, company/institution).</p>


      <h1>2. Purpose</h1>
      <p>The purpose of these GCU is to define the conditions under which the User benefits from access to the Site.</p>

      <h1>3. Site description</h1>
      <p>The purpose of the Site is to inform Users about the field of hydrogen in naval transport.</p>
      <p>The functionalities and services offered on the Site are as follows (the “Services”):</p>
      <ul>
        <li>Access to a rich repository of legislative, educational and technical documents on hydrogen and fuel cells in naval transport, 
          including access to a database of hydrogen-powered ship projects;</li>
        <li>Access to a simulation tool for the pre-sizing of alternative propulsion systems (via the “H2SHIPS DESIGN TOOL” tab);</li>
        <li>Only for Users who have created an Account: the possibility to submit Contributions relating to hydrogen in the field of naval transport (the “Reserved Service”). 
          Contributions will be reviewed by the administrator and, if found suitable, published on the Site.</li>
      </ul>
      <p>Unless expressly provided otherwise, any new feature that enhances or increases one or more existing Services on the Site will be subject to these GCU.</p>

      <h1>4. Acceptance modifications of the GCU</h1>
      <p><b>4.1.</b> Access to the Site is subject to compliance with the GCU and is strictly reserved for Users as defined below.</p>
      <p>For Users who do not create an Account, access to and use of the Site implies unreserved acceptance of these GCU.
         By browsing the site, the User acknowledges that he/she has read the GCU and undertakes to comply with them. 
         The GCU can be accessed at any time via a link in the footer of the Site.
      </p>
      <p>
          Users creating an Account have access to an additional service. 
          Consequently, registration for a user Account and access to the Reserved Service requires express acceptance of these GCU. When creating an account, the User must check a box to this effect. 
          This acceptance is an indispensable condition for finalizing registration and access to Reserved Service.
      </p>
      <p>
      <b>4.2 </b>EIFER, in its capacity as editor, reserves the right to unilaterally modify or update the GCU at any time, 
      in particular in order to comply with any legal, jurisprudential, editorial and/or technical developments, as well as any modifications to the general conditions of use of the Host's services.
      </p>
      <p>
      For unregistered Users, continued browsing of the site after modifications to the GCU implies acceptance of the new version.
      </p>
      <p>
      For registered Users, the User will be informed by e-mail. 
      The User must then accept the new GCU by checking a box to this effect the next time he/she connects to the Site. 
      If the User does not accept the GCU, he/she will no longer be able to access the Site but may continue to contact EIFER at the following address: <a href="Christian-Frederic.Berthon@eifer.org">Christian-Frederic.Berthon@eifer.org</a>.
      </p>

      <h1>5. Account creation</h1>

        <h2>5.1 Obligation to create an Account</h2>
        <p>To be able to submit Contributions on the Site, the User must create a User Account.</p>

        <h2>5.2 Account creation</h2>
        <p>Any User may create a User Account by providing his or her Registration Data via the “sign up” tab.</p>
        <p>The Account creation procedure comprises the following steps:</p>
        <ul>
          <li>The User clicks on the 'sign up' tab;</li>
          <li>The User fills in and validates his/her Registration Data;</li>
          <li>The User will have to validate his registration via an account creation confirmation link received by e-mail.</li>
        </ul>

        <h2>5.3 Password and security</h2>
        <p>When creating an Account, the User is asked to create a login and password, and to enter an email address and, optionally, the name of its company/institution.
           The User may, if he/she wishes, modify this information via the ‘Edit profile’ tab.</p>
           <p>
           At the end of the Account creation procedure and after acceptance of these GCU, the User will receive a message confirming the creation of his Account at the e-mail address indicated by him on the registration form.
           </p>
           <p>
           The User is solely responsible for preserving the confidentiality of the Registration Data provided by him/her during the Account creation procedure. 
           The User undertakes to take all necessary measures to ensure the confidentiality of such data.
           </p>
           <p>
           The User undertakes to inform EIFER immediately of any unauthorized use of his/her login on the Site, password or Registration Data, and more generally of any breach of security of which he/she becomes aware. 
           In addition, the User must log out at the end of each session.
           </p>
        
        <h2>5.4 Account deletion</h2>
        <p>
          The User is free to delete his or her Account at any time.
           If the User loses his or her password or has difficulty accessing his or her Account, 
           he or she should send a request to the following e-mail address: <a href="mailto:{process.env.CONTACT_EMAIL}">{process.env.CONTACT_EMAIL}</a>.
        </p>
        <p>
        EIFER reserves the right to terminate access to all or part of the Site, or to delete the User's Account, 
        as well as the right to delete any Contribution due to total inactivity of the Account for a period of at least one year.
        </p>

        <h1>6. Rights to use the Site</h1>

        <p>
        The User has only a personal and internal, non-commercial, non-exclusive, non-transferable and revocable right to the information available on the Site, whatever its nature, including the Content.
        </p>
        <p>
        The User undertakes not to use the Site and the information provided to :
        </p>
        <ul>
          <li>
          manipulate, in any way whatsoever, the Content and more generally the information provided on the Site in such a way as to conceal the source and origin of 
          the information transmitted on the Site,
          </li>
          <li>
          download, display, transmit by e-mail or in any other way, any content containing computer viruses or any code, files or programmes designed to interrupt, 
          destroy or limit the functionality of any software, computer or telecommunications tool, without this list being exhaustive,
          </li>
          <li>
          commit any action having a disruptive effect and/or hindering real-time communication capabilities,
          </li>
          <li>
          hinder or disrupt the Site, the servers or the networks connected to the service, or refuse to comply with the required conditions, 
          procedures, general rules and/or regulatory provisions applicable to the network connected to the Site;
          </li>
          <li>
          download, collect or store personal and/or nominative data relating to other Users registered on the Site.
          </li>
        </ul>

        <h1>7. Right of withdrawal and deletion</h1>
        <p>EIFER, or any person or entity appointed by it, shall be entitled to withdraw or delete any Contribution and Content that violates these GCU and, more generally, the laws in force.</p> */}


      </div>
      </div>
      
  );
}

export default GeneralConditionsOfUse;
