
function PrivacyPolicy() {
  return (
    <div className="container mb-5">
      <div className="row justify-content-center">
        <h1>Privacy Policy</h1>
      
      <h2 className="objectiveContent__title">1. Introduction</h2>
      <p>EIfER – European Institute for Energy Research EDF-KIT EWIV ("EIfER") is committed to protecting your personal data in compliance with the General Data Protection Regulation (GDPR) and applicable German data protection laws. This Privacy Policy explains how we collect, use, and protect your personal data when using our website.</p>
      
      <h2 className="objectiveContent__title">2. Data Controller and Data Protection Officer</h2>
      <p>The entity responsible for the processing of personal data is:</p>
      <p><strong>EIfER – European Institute for Energy Research EDF-KIT EWIV</strong><br />
        Emmy-Noether-Str. 11, D – 76131 Karlsruhe, Germany<br />
        +49 (0) 721 6105 1330<br />
        contact@eifer.org</p>
      <p>The Data Protection Officer (DPO) can be contacted at:</p>
      <p><strong>EIfER – European Institute for Energy Research EDF-KIT EWIV – Data Protection Officer</strong><br />
        Emmy-Noether-Str. 11, D – 76131 Karlsruhe, Germany<br />
        +49 (0) 721 6105 1330<br />
        dsb@eifer.org</p>
      
      <h2 className="objectiveContent__title">3. What This Privacy Policy Covers</h2>
      <p>This Privacy Policy fulfills the legal requirements for transparency in the processing of personal data.</p>
      
      <h2 className="objectiveContent__title">4. Data We Collect</h2>
      <ul>
          <li><strong>Account Data:</strong> Name, email address, and information voluntarily provided when creating an account.</li>
          <li><strong>Technical Data:</strong> IP addresses, browser type, and other technical data.</li>
          <li><strong>Session Cookies:</strong> Used for authentication and security purposes.</li>
          <li><strong>Communication Data:</strong> Emails and correspondence.</li>
      </ul>
      
      <h2 className="objectiveContent__title">5. Purpose of Data Processing</h2>
      <ul>
          <li>To enable account creation and manage user submissions.</li>
          <li>To communicate with users.</li>
          <li>To ensure website functionality and security.</li>
          <li>To comply with legal obligations.</li>
      </ul>
      
      <h2 className="objectiveContent__title">6. Legal Basis for Processing</h2>
      <ul>
          <li>Article 6(1)(a) GDPR (Consent)</li>
          <li>Article 6(1)(b) GDPR (Contract Performance)</li>
          <li>Article 6(1)(f) GDPR (Legitimate Interest)</li>
      </ul>
      
      <h2 className="objectiveContent__title">7. Data Retention</h2>
      <ul>
          <li>Account data is retained for one year after deletion.</li>
          <li>Technical data is stored only as long as necessary for security.</li>
          <li>Emails are retained as long as necessary.</li>
      </ul>
      
      <h2 className="objectiveContent__title">8. Who Will Receive My Data?</h2>
      <ul>
          <li>Hosting Provider (within the EEA).</li>
          <li>Legal Compliance Authorities if required.</li>
          <li>No unauthorized third-party sharing.</li>
      </ul>
      
      <h2 className="objectiveContent__title">9. User Rights Under GDPR</h2>
      <ul>
          <li>Right to Access (Art. 15 GDPR)</li>
          <li>Right to Rectification (Art. 16 GDPR)</li>
          <li>Right to Erasure (Art. 17 GDPR)</li>
          <li>Right to Restrict Processing (Art. 18 GDPR)</li>
          <li>Right to Data Portability (Art. 20 GDPR)</li>
          <li>Right to Object (Art. 21 GDPR)</li>
          <li>Right to Withdraw Consent (Art. 7(3) GDPR)</li>
          <li>Right to Lodge a Complaint (Art. 77 GDPR)</li>
      </ul>
      
      <h2 className="objectiveContent__title">10. Security Measures</h2>
      <p>We implement appropriate technical and organizational security measures to protect personal data.</p>
      
      <h2 className="objectiveContent__title">11. Cookies and Tracking</h2>
      <ul>
          <li>Session cookies are used for authentication.</li>
          <li>No tracking cookies or third-party analytics tools are used.</li>
      </ul>
      
      <h2 className="objectiveContent__title">12. Changes to this Privacy Policy</h2>
      <p>We reserve the right to update this Privacy Policy to comply with legal requirements or reflect changes in our data processing practices.</p>
      
      <h2 className="objectiveContent__title">13. Complaints and Supervisory Authority</h2>
      <p>If you believe your data is not being processed in accordance with the GDPR, you have the right to lodge a complaint with the data protection authority:</p>
      <p><strong>Baden-Württemberg State Commissioner for Data Protection and Freedom of Information</strong><br />
        <a href="https://www.baden-wuerttemberg.datenschutz.de">www.baden-wuerttemberg.datenschutz.de</a></p>
    
    </div>
    </div>
      
  );
}

export default PrivacyPolicy;
