
function LegalNotice() {
  return (
    <div className="container mb-5">
      <div className="row justify-content-center">

<h1>Imprint (Legal Notice)</h1>

<h2>Service Provider</h2>
<p>
    EIfER Europäisches Institut für Energieforschung EDF-KIT EWIV<br />
    Emmy-Noether-Straße 11<br />
    76131 Karlsruhe<br />
    Deutschland
</p>

<h2>Legal Representative</h2>
<p>Dr Nurten Avci</p>

<h2>Contact Information</h2>
<p>
    Phone: +49 (0)721 6105-1330<br />
    Fax: +49 (0)721 6105-1332<br />
    Email: <a href="mailto:contact@eifer.org">contact@eifer.org</a>
</p>

<h2>Commercial Register Information</h2>
<p>
    Registered at: Baden-Württemberg Amtsgericht Mannheim<br />
    Register Number: HRA 104823
</p>

<h2>VAT Identification Number</h2>
<p>According to § 27a Umsatzsteuergesetz (UStG): DE814587731</p>

<h2>Responsible for Content according to §55 RStV</h2>
<p>EIfER Europäisches Institut für Energieforschung EDF-KIT EWIV</p>

<h2>Liability for Content</h2>
<p>
    The content of this website is created with the greatest possible care. However, EIfER does not guarantee the correctness, completeness, or topicality of the provided content. As a service provider, EIfER is responsible for its own content on these pages in accordance with general laws. However, EIfER is not obligated to monitor transmitted or stored external information or to investigate circumstances indicating illegal activity, except as required by law.
</p>

<h2>Liability for Links</h2>
<p>
    This website may contain links to external third-party websites. EIfER has no influence on their content and assumes no liability for such external content. The respective provider or operator of the linked pages is always responsible for their content. At the time of linking, no legal violations were recognizable. If any infringement becomes known, EIfER will remove the respective links immediately.
</p>

<h2>Reporting Illegal Content and Copyright Violations</h2>
<p>
    If you wish to report illegal content or copyright violations published on this website, please contact: <br />
    Email: <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL || "default@example.com"}`}>{process.env.REACT_APP_CONTACT_EMAIL || "default@example.com"}</a><br />
    Reports should include a description of the content, the URL where it was found, and a justification for
     why it is considered illegal or infringing copyright. 
    If applicable, please provide evidence of ownership for copyright-related complaints. 
    EIfER will review the report and take appropriate action where necessary, which may include removal or
     modification of the reported content. EIfER reserves the right to reject reports that are vague, misleading, 
     or lack legal justification.
</p>

<h2>Copyright Notice</h2>
<p>
    All content on this website, including texts, images, graphics, and other materials, is protected by copyright laws. Any reproduction, modification, distribution, or storage of such content beyond what is legally permitted requires prior written consent from EIfER or the respective rights holder.
</p>

<h2>Dispute Resolution</h2>
<p>EIfER is neither willing nor obligated to participate in dispute resolution proceedings before a consumer arbitration board.</p>

      {/* <h1>Legal information - H2SHIPS website editor :</h1>
      <p>
      <b>EIfER Europäisches Institut für Energieforschung EDF-KIT EWIV</b>, European Economic Interest Grouping, 
      registered in the Commercial Register of Mannheim, Germany, under number HRA 104823, VAT number DE814587731, 
      with registered office at Emmy-Noether-Strasse 11, D-76131 Karlsruhe, Germany.
      </p>
      <p>Phone number: +49 (0) 721 61051330</p>
      <p>Email : contact@eifer.org</p>
      <p>Person responsible for the Site's publication: Mr Christian-Frédéric Berthon, in his capacity as researcher at EIFER,
         <a href="mailto:Christian-Frederic.Berthon@eifer.org">Christian-Frederic.Berthon@eifer.org</a>.</p>
      <p>The H2SHIPS site was developed by EIFER and the University of Birmingham as part of the H2SHIPS project, 
        funded by the European Union under the Interreg North-West Europe program (project n°NWE849).</p>

        <h1>H2SHIPS website hosting</h1>
        <p>The H2SHIPS website is hosted on servers located in Europe by Amazon Web Services Inc, PO Box 81226, Seattle, WA 981808-1226 - USA. 
          <a href="https://aws.amazon.com/fr/compliance/eu-data-protection/">https://aws.amazon.com/fr/compliance/eu-data-protection/</a> .</p>

        <h1>Hosting personal Data</h1>
        <p>Personal data is hosted on servers located in Europe by Amazon Web Services Inc, PO Box 81226, Seattle, WA 981808-1226 - USA. 
          <a href="https://aws.amazon.com/fr/compliance/eu-data-protection/">https://aws.amazon.com/fr/compliance/eu-data-protection/</a>.</p> */}
    </div>
    </div>
      
  );
}

export default LegalNotice;
