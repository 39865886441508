import React from "react";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import Home from "./pages/Home";
import OverView from "./pages/Overview";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import News from "./pages/News";
import Port from "./pages/Port";
import Enquiry from "./pages/Enquiry";
import NewsSingle from "./pages/NewsSingle";

import GenericContentList from "./pages/GenericContentList";
import Links from "./pages/Links";
import Events from "./pages/Events";
import Concepts from "./pages/Concepts";
import Pilots from "./pages/Pilots";
import SignUp from "./pages/Signup";
import ResetPassword from "./pages/ResetPassword";
import ReportDetails from "./components/reportContent/Reports/ReportDetails";
import Faq from "./pages/Faq";
import Legislative from "./pages/Legislative";
import UserDashboardHome from "./pages/UserDashboardHome";
import UserDashboardForm from "./pages/UserDashboardForm";
import ForgetPassord from "./pages/ForgetPassord";
import VerifyEmailAddress from "./pages/VerifyEmailAddress";
import ShippingProjects from "./pages/ShippingProjects";
import InitiativeWorkshop from "./pages/InitiativeWorkshop";
//import UserDashboardHome from "./pages/UserDashboardHomeOld";
import UserActivity from "./pages/UserActivity";
import Calculator from "./pages/Calculator";
import LegalNotice from "./pages/LegalNotice";
import GeneralConditionsOfUse from "./pages/GeneralConditionsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function RequireAuth({ children }) {
  const isAuthenticated = localStorage.getItem("accessToken");
  let location = useLocation();

  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>
    {/* <TopBar/> */}
    {children}
  </>;
}
export default function AppRouter() {
  return (
    <Routes>
      {/* new designs ======================*/}
      <Route exact path="/" element={<Home />} />
      <Route exact path="/calculator" element={<Calculator />} />
      <Route exact path="/overview" element={<OverView />} />
	  {/*<Route
        exact
        path="/user-dashboard-home"
        element={
          <RequireAuth>
            <UserDashboardHome />
          </RequireAuth>
        }
      />*/}
      <Route
        exact
        path="/user-dashboard"
        element={
          <RequireAuth>
            <UserDashboardHome />
          </RequireAuth>
        }
      />

	   <Route
        exact
        path="/my-activity"
        element={
          <RequireAuth>
            <UserActivity />
          </RequireAuth>
        }
      />

      <Route
        exact
        path="/user-dashboard-form"
        element={
          <RequireAuth>
            <UserDashboardForm />
          </RequireAuth>
        }
      />
      <Route exact path="/concepts" element={<Concepts />} />
      <Route exact path="/pilots" element={<Pilots />} />
      <Route exact path="/news" element={<News />} />
      <Route exact path="/news/news-details" element={<NewsSingle />} />
      <Route exact path="/port" element={<Port />} />
      {/* <Route exact path="/reports" element={<Reports />} /> */}
      <Route path="/report-details" >
        <Route path=":id" element={<ReportDetails />} >
        </Route>

      </Route>

      <Route exact path="/legislative" element={<Legislative />} />


      <Route exact path="/links" element={<Links />} />

      <Route exact path="/shipping-projects" element={<ShippingProjects />} />
      <Route
        exact
        path="/initiatives-workshops"
        element={<InitiativeWorkshop />}
      />
      
      <Route exact path="/events" element={<Events />} />
      {/* <Route exact path="/faq" element={<Faq />} /> */}
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/signup" element={<SignUp />} />
      <Route exact path="/forget-password" element={<ForgetPassord />} />
      <Route exact path="/reset-password" element={<ResetPassword />} />
      <Route
        exact
        path="/verify-email-address"
        element={<VerifyEmailAddress />}
      />
      <Route
        path="/profile"
        element={
          <RequireAuth>
            <Profile />
          </RequireAuth>
        }
      />
      <Route
        path="content"
      >
        <Route
          path=":id"
        >
          <Route
            path=":slug"
            element={<GenericContentList />}
          >
          </Route>
        </Route>
      </Route>


      <Route exact path="/legal/legal_notice" element={<LegalNotice />} />
      <Route exact path="/legal/general_conditions_of_use" element={<GeneralConditionsOfUse />} />
      <Route exact path="/legal/privacy_policy" element={<PrivacyPolicy />} />

    </Routes>
  );
}
