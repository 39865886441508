import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";

import { Button } from "@mui/material";
import { useFormik, Formik } from "formik";
import Fade from "react-reveal/Fade";
import { useNavigate, useSearchParams } from "react-router-dom";
import navLogo from "../../assets/images/navLogo.png";
import { setDataByAuthType, submit_auth } from "../../misc/auth";

function AuthComponent(props) {
  let navigate = useNavigate();
  
  let [searchParams, setSearchParams] = useSearchParams();
  
  let [state, setState] = useState({
    status_message: {
      type: "success",
      message: "",
    },
    authType: props.authType,
  });
  let { authType, status_message } = state;
  let initialValues = {
    ...setDataByAuthType(authType).initialValues,
    agree_terms: false,
    agree_privacy: false,
  };  let validationSchema = setDataByAuthType(authType).validationSchema;


  useEffect(() => {

    setState({
      ...state,
      status_message: {
        type: "",
        message: "",
      },
    });
  }, [authType]);


  return (
    <div className="auth auth-wrapper">
      {/* <Slide collapse bottom> */}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          if (authType === "signup" && (!values.agree_terms || !values.agree_privacy)) {
            setState({
              ...state,
              status_message: {
                type: "error",
                message: "You must agree to the Terms and Privacy Policy.",
              },
            });
            return;
          }
          
          let res = await submit_auth(authType, values, navigate, searchParams);
          if (res.data) {
            setState({
              ...state,
              status_message: {
                type: "success",
                message: res.data.message,
              },
            });
          } else {
            let { message, errors } = res.response.data;

            for (const property in errors) {
              message = message + ` ${errors[property]}`;
            }

            setState({
              ...state,
              status_message: {
                type: "error",
                message,
              },
            });
          }
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <div className="container">
              <div className="row justify-content-md-center">
                <div className="col-md-12">
                  <div className="auth-logo">
                    <img src={navLogo} alt="nav-logo" />
                  </div>
                  <h1 className="text-center auth-title">
                    {setDataByAuthType(authType).title}
                  </h1>
                  <Fade>
                    <div className="auth-inputs">
                      {authType === "signup" && (
                        <TextField
                          fullWidth
                          variant="filled"
                          id="name"
                          name="name"
                          label="Username"
                          value={props.values.name}
                          onChange={props.handleChange}
                          error={
                            props.touched.name && Boolean(props.errors.name)
                          }
                          helperText={props.touched.name && props.errors.name}
                        />
                      )}
                      {(authType === "login" ||
                        authType === "signup" ||
                        authType === "forget_password") && (
                          <TextField
                            fullWidth
                            variant="filled"
                            id="email"
                            name="email"
                            label="Email"
                            value={props.values.email}
                            onChange={props.handleChange}
                            error={
                              props.touched.email && Boolean(props.errors.email)
                            }
                            helperText={props.touched.email && props.errors.email}
                          />
                        )}
                      {authType === "signup" && (
                        <TextField
                            fullWidth
                            variant="filled"
                            id="company"
                            name="company"
                            label="Company/Institution (Optional)"
                            value={props.values.company}
                            onChange={props.handleChange}
                            error={
                              props.touched.company && Boolean(props.errors.company)
                            }
                            helperText={props.touched.company && props.errors.company}
                          />
                        )}
                      {(authType === "login" ||
                        authType === "signup" ||
                        authType === "reset_password") && (
                          <TextField
                            fullWidth
                            variant="filled"
                            id="password"
                            name="password"
                            label="Password"
                            type="password"
                            value={props.values.password}
                            onChange={props.handleChange}
                            error={
                              props.touched.password &&
                              Boolean(props.errors.password)
                            }
                            helperText={
                              props.touched.password && props.errors.password
                            }
                          />
                        )}
                      {(authType === "signup" ||
                        authType === "reset_password") && (
                          <TextField
                            fullWidth
                            variant="filled"
                            id="password_confirmation"
                            name="password_confirmation"
                            label="Password Confirmation"
                            type="password"
                            value={props.values.password_confirmation}
                            onChange={props.handleChange}
                            error={
                              props.touched.password_confirmation &&
                              Boolean(props.errors.password_confirmation)
                            }
                            helperText={
                              props.touched.password_confirmation &&
                              props.errors.password_confirmation
                            }
                          />
                        )}
                      {(authType === "signup") && (
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="agree_terms"
                                name="agree_terms"
                                checked={props.values.agree_terms}
                                onChange={props.handleChange}
                                color="primary"
                              />
                            }
                            label={
                              <>
                                I Agree to <a href="/legal/general_conditions_of_use" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                              </>
                            }
                          />
                          <FormHelperText error={props.touched.agree_terms && Boolean(props.errors.agree_terms)}>
                            {props.touched.agree_terms && props.errors.agree_terms}
                          </FormHelperText>
                        </div>
                      )}
                      {(authType === "signup") && (
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="agree_privacy"
                                name="agree_privacy"
                                checked={props.values.agree_privacy}
                                onChange={props.handleChange}
                                color="primary"
                              />
                            }
                            label={
                              <>
                                I Agree to the <a href="/legal/privacy_policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                              </>
                            }
                          />
                          <FormHelperText error={props.touched.agree_privacy && Boolean(props.errors.agree_privacy)}>
                            {props.touched.agree_privacy && props.errors.agree_privacy}
                          </FormHelperText>
                        </div>
                      )}

                    </div>
                  </Fade>
                  <p
                    className={
                      status_message.type === "success"
                        ? "text-center success-color"
                        : "text-center error-color"
                    }
                  >
                    {status_message.message}
                  </p>
                  <div className="submit-buttons">
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="large"
                    >
                      {setDataByAuthType(authType).buttonLabel}
                    </Button>
                  </div>
                  <div className="text-center">
                    {authType === "login" && (
                      <>
                        <span> Don't have an account?</span>
                        <Button
                          // onClick={() =>
                          //   setState({ ...state, authType: "signup" })
                          // }
                          onClick={() => navigate("/signup")}
                        >
                          <u>Sign up</u>
                        </Button>
                      </>
                    )}
                  </div>
                  <div className="auth__bottomLabel text-center">
                    {authType === "login" && (
                      <Button
                        onClick={() => navigate("/forget-password")}
                      >
                        Forgot Password?
                      </Button>
                    )}
                    {(authType === "signup" ||
                      authType === "reset_password" ||
                      authType === "forget_password") && (
                        <Button
                          onClick={() => navigate("/login")}
                        // onClick={() =>
                        //   setState({ ...state, authType: "login" })
                        // }
                        >
                          Login
                        </Button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
        {/* </Slide> */}
      </Formik>
    </div>
  );
}


export default AuthComponent;